<div class="container p-0">
	<form [formGroup]="propertyAlertForm" (ngSubmit)="onSubmit()">
		<div class="row mx-0 px-3 justify-content-between align-items-center dialog-header">
			<div class="col-auto px-0">
				<h4 class="heading-4-regular fg-general-5 m-0">Property Alert</h4>
			</div>
			<div class="col-auto px-0">
				<button mat-icon-button mat-dialog-close class="close-btn">
					<mat-icon svgIcon="close-2" class="justify-content-center"></mat-icon>
				</button>
			</div>
		</div>

		<div class="row mx-0 px-3 mt-3 justify-content-between align-items-center">
			<div class="col-12 px-0">
				<span class="text-body-regular fg-general-4 mt-4">Select the County</span>

				<mat-form-field appearance="outline" class="text-body-regular w-100 no-subscript">
					<mat-select formControlName="countyId" [placeholder]="'Select the County'">
						@for (county of countyList; track county) {
							<mat-option [value]="county.id">{{ county.name }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<a class="text-decoration-underline fg-general-3 fs-12px lh-16px" mat-dialog-close routerLink="property-alerts">Manage Email Alerts</a>
			</div>
		</div>

		<div class="row my-3 mx-0 px-3 justify-content-between align-items-center">
			<div class="col-12 px-0">
				<mat-checkbox formControlName="emailAlert" color="primary">
					<span class="fs-12px fw-400 lh-18px fg-general-5">Email me when ads match this search</span>
				</mat-checkbox>
			</div>
		</div>

		<div class="row mx-0 gap-3 p-3 dialog-actions">
			<div class="col px-0">
				<findqo-button
					[isDisabled]="!propertyAlertForm.getRawValue().emailAlert || propertyAlertForm.invalid"
					type="submit"
					class="w-100 d-flex flex-column"
					[label]="'Enable property listing alert'"
				></findqo-button>
			</div>
		</div>
	</form>
</div>
