import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FiltersApi } from '@core/data-access/apis/filters.api';
import { SnackService } from '@core/services/snack.service';
import { PropertyAlertsFacade } from '@feature/property-alerts/property-alerts.facade';
import { ButtonType } from '@shared/components/button/button.component';

@Component({
	selector: 'findqo-property-alert-dialog',
	templateUrl: './property-alert-dialog.component.html',
	styleUrls: ['./property-alert-dialog.component.scss'],
})
export class PropertyAlertDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public modalData,
		private readonly dialogRef: MatDialogRef<PropertyAlertDialogComponent>,
		private readonly filterApi: FiltersApi,
		private fb: FormBuilder,
		private propertAlert: PropertyAlertsFacade,
		private snackService: SnackService
	) {}

	readonly buttonType = ButtonType;
	countyList = [];

	propertyAlertForm = this.fb.group({
		countyId: ['', [Validators.required]],
		section: ['', [Validators.required]],
		emailAlert: [true, [Validators.required]],
		aisle: ['', [Validators.required]],
	});

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	onSubmit() {
		if (this.propertyAlertForm.invalid) {
			return;
		}

		this.propertAlert.addAlert(this.propertyAlertForm.value).subscribe({
			next: () => {
				this.snackService.open({ data: { message: 'Success! Property Alert enabled for this search.', type: 'success' }, panelClass: ['snackbar-success'] });

				this.dialogRef?.close();
			},
			error: (e) => {
				this.snackService.open({
					data: {
						message:
							e?.error?.error?.id === 'propertyAlertAlreadyExists' ? 'Property Alert already exists for this search.' : e?.error?.error?.errors[0]?.messages[0],
						type: e?.error?.error?.id === 'propertyAlertAlreadyExists' ? 'info' : 'error',
					},
					panelClass: 'snackbar-error',
				});
			},
		});
	}

	ngOnInit(): void {
		this.filterApi.getCountyList({}).subscribe((res) => {
			if (res && res?.['all']) {
				this.countyList = res?.['all'];

				if (this.modalData.location.county) {
					this.propertyAlertForm.patchValue({
						countyId: this.countyList.find((res) => res.name.trim().toLowerCase() === this.modalData.location.county.trim().toLowerCase())?.id,
					});
				}

				if (this.modalData.location.countyReference) {
					this.propertyAlertForm.patchValue({
						countyId: this.countyList.find((res) => res.reference.trim().toLowerCase() === this.modalData.location.countyReference.trim().toLowerCase())?.id,
					});
				}
			}
		});

		this.propertyAlertForm.patchValue({
			section: this.modalData.section,
			aisle: this.modalData.aisle,
		});
	}
}
